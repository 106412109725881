import ContentBlocks from '../components/content-blocks'
import HeaderHero from '../components/header-hero'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { useContactUsPage } from '../hooks/contact-us'
import '../components/contact.scss'
import '../components/radio.scss'

// TODO: Delete this once it's approved
// This file is here as we can only test on PROD as the domain is whitelisted for use with dynamics 365.
// We are waiting on Rob Steele to test the form and see if the data is picked up by dynamics 365.
// This file will replace contact.js when it's proven to work.

const ContactTemp = () => {
    const { contactUs } = useContactUsPage().contactUsContentfulPage
    const contactUsData = contactUs && contactUs[0].node

    const uniqueDynamicsID = 'ShZbizVEmjaqcfzcCY_ADLY0zpnB_Oh8bfmuOwZhZvg'

    // useEffect(() => {
    //     dynamics365Script(uniqueDynamicsID)
    // }, [])

    // add Microsoft Dynamics 365 Script tag
    // useScript('https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.58.1075.0')

    return (
        <Layout>
            <SEO
                title={contactUsData.title}
                description={contactUsData.metaDescription && contactUsData.metaDescription.metaDescription ? contactUsData.metaDescription.metaDescription : null}
                imageUrl={contactUsData.image && contactUsData.image.image.fluid.src.replace(/^\/\//, 'https://')}
                tags={contactUsData.metaTags && contactUsData.metaTags.metaTags}
            />
            <HeaderHero
                title={contactUsData.title}
                image={contactUsData.image}
                leadingParagraph={contactUsData.leadingParagraph}
                path={'/contact/'}
            />
            <div className="contact">
                <div className="contact-content-blocks || contact-section">
                    <ContentBlocks data={contactUsData.contentBlocks}/>
                </div>
                <div className="contact-section">
                    <div id={`d${uniqueDynamicsID}`}/>
                    <script language="javascript" type="text/javascript" dangerouslySetInnerHTML={{ __html: `(function (id, f, t, ws, ms_tr_il_08, ms_tr_il_w_01) { var tr = function (cb) { var count = 0; var callback = function () { if (count == 0) { count++; if (w) { w.w(id, t, cb); } } }; var ts = document.createElement('script'); ts.src = ws; ts.type = 'text/javascript'; ts.onload = callback; ts.onreadystatechange = function () { if (this.readyState == 'complete' || this.readyState == 'loaded') { callback(); } }; var head = document.getElementsByTagName('head')[0]; head.appendChild(ts); }; if (typeof ms_tr_il_08 === 'function') { if (ms_tr_il_w_01 === null) { tr(function() { ms_tr_il_08(id, f, t); }); } else { ms_tr_il_w_01.w(id, t, function(websiteVisitedParams) { ms_tr_il_08(id, f, t, websiteVisitedParams); }, null); } } else { tr(); }})('${uniqueDynamicsID}', 'https://61e6c1056f884679b2e346929200beee.svc.dynamics.com/f', 'https://61e6c1056f884679b2e346929200beee.svc.dynamics.com/t', 'https://61e6c1056f884679b2e346929200beee.svc.dynamics.com/t/w', typeof ms_tr_il_08 === "undefined" ? null : ms_tr_il_08, typeof ms_tr_il_w_01 === "undefined" ? null : ms_tr_il_w_01);` }}/>
                    <form className="contact-form || contact-section">
                        <p><b>Contact Us</b></p>
                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                        <div className="field">
                            <div className="control">
                                <label className="label">First Name <span className="asterisk">*</span></label>
                                <input className="input required" name="firstName" type="text" placeholder="First name"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Last Name <span className="asterisk">*</span></label>
                                <input className="input required" name="lastName" type="text" placeholder="Last name"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Email Address <span className="asterisk">*</span></label>
                                <input className="input required" name="email" type="email" placeholder="Email"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Phone Number</label>
                                <input className="input" name="tel" type="tel" placeholder="Phone number"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Company or School Name <span className="asterisk">*</span></label>
                                <input className="input required" name="company" type="text" placeholder="Company or school"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">What&apos;s your enquiry? <span className="asterisk">*</span></label>
                                <div className="select">
                                    <select name="region">
                                        <option>Choose</option>
                                        <option>Principals / programmes</option>
                                        <option>Volunteer / Volunteer opportunity</option>
                                        <option>Partner / Partner opportunity</option>
                                        <option>General</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label className="label">Region <span className="asterisk">*</span></label>
                                <div className="select">
                                    <select name="region">
                                        <option>Choose</option>
                                        <option>Auckland</option>
                                        <option>Australia</option>
                                        <option>Bay of Plenty</option>
                                        <option>Canterbury</option>
                                        <option>Chatham Islands</option>
                                        <option>Christchurch</option>
                                        <option>Gisborne</option>
                                        <option>Hawkes Bay</option>
                                        <option>Manawatu-Wanganui</option>
                                        <option>Marlborough</option>
                                        <option>Nelson</option>
                                        <option>Northland</option>
                                        <option>Otago</option>
                                        <option>Southland</option>
                                        <option>Taranaki</option>
                                        <option>Tasman</option>
                                        <option>Waikato</option>
                                        <option>Wellington</option>
                                        <option>West Coast</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Are you a School Employee? <span className="asterisk">*</span></label>
                            <div className="control form-field radio">
                                <input type="radio" name="employee" id="yes" value="yes"/><label className="form-field-label" htmlFor="yes">Yes</label>
                                <input type="radio" name="employee" id="no" value="no"/><label className="form-field-label" htmlFor="no">No</label>
                            </div>
                        </div>
                        <button type="submit" value="Submit" name="submit" className="button primary">Submit</button>
                    </form>
                    <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.58.1075.0"/>
                    <div className="d365-mkt-config" style={{ display : 'none' }} data-website-id={uniqueDynamicsID} data-hostname="61e6c1056f884679b2e346929200beee.svc.dynamics.com"/>
                </div>
            </div>
            <iframe className="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.4951134349076!2d174.78084331529348!3d-36.926334279922635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6b2f869f30841a7!2sSpringboard%20Trust!5e0!3m2!1sen!2snz!4v1580783988113!5m2!1sen!2snz"
                frameBorder="0"
                allowFullScreen=""
            />
        </Layout>
    )
}

export default ContactTemp
